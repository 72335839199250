<template>
  <div>
    <div class="ajjjj" id="wrap">
      <!-- logo -->
      <div class="logo fbox fbox-acenter">
        <div class="imgBox">
          <img :src="$store.state.projectInfoServe?.platformLogoPath" />
        </div>
        <span class="font-pmzd">{{
          $store.state.projectInfoServe?.platformName
        }}</span>
      </div>
      <!-- nav -->
      <div class="nav fbox fbox-acenter">
        <div class="navList">
          <div
            :class="{ li: true, active: activeName == item.name }"
            v-for="(item, index) in navList"
            :key="index"
            @click="activeName = item.name"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="login">
        <div class="btn" @click="loginGo(item)" v-for="item in loginGoList">
          {{ item }}
        </div>
      </div>
    </div>
    <!-- 站位 -->
    <div style="width: 100%; height: 80px"></div>
  </div>
</template>
<script>
export default {
  props: ["tap"],
  data() {
    return {
      // 展示登录列表
      showLogin: false,
      activeName: "",
      bgColor: "rgba(0,0,0,0.5);",
      loginGoList: ["用户注册", "用户登录", "专家登录", "定标系统"],
      navList: [
        {
          name: "home",
          title: "首页",
        },
        {
          name: "zbinfo",
          title: "招标信息",
        },
        {
          name: "news",
          title: "新闻公告",
        },
        {
          name: "four",
          title: "保函办理",
        },
        {
          name: "five",
          title: "CA办理",
        },
        {
          name: "Tool",
          title: "常用工具",
        },
      ],
    };
  },

  mounted() {},
  methods: {
    //跳转其他url
    loginGo(title) {
      if (title == "用户登录") {
        location.href = this.$store.state.userUrl;
      } else if (title == "专家登录") {
        location.href = this.$store.state.expertUrl;
      } else if (title == "定标系统") {
        location.href = this.$store.state.picketageExpertUrl;
      } else if (title == "用户注册") {
        location.href = this.$store.state.registerUrl;
      }
    },
  },

  watch: {
    "$route.name"() {
      this.activeName = this.$route.name;
    },
    activeName(newVal) {
      switch (newVal) {
        case "home":
          this.$router.push("/home");
          break;
        case "news":
          this.$router.push("/news");
          break;
        case "zbinfo":
          this.$router.push("/zbinfo");
          break;
        case "five":
          window.open(this.$store.state.ggzUrl, "__blank");
          setTimeout(() => {
            this.activeName = "home";
          }, 1000);
          break;
        case "Tool":
          this.$router.push("/Tool");
          break;
        case "four":
          window.open(this.$store.state.websiteUrl, "__blank");
          setTimeout(() => {
            this.activeName = "home";
          }, 1000);
          break;
      }
    },
    tap(newVal) {
      this.activeName = newVal;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .ajjjj {
    display: none;
  }
}
.ajjjj {
  width: 100%;
  height: 80px;
  padding: 0 10vw;
  display: flex;
  align-items: center;
  color: white;
  z-index: 999;
  position: fixed;
  background-color: #fff;
  // overflow: hidden;
  // background-image: url('@/assets/images/header-bgc.png');
  // background-size: 100% 100%;
  // background-position: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.logo {
  color: #333;
  font-size: 26px;
  font-weight: 550;
  display: flex;
  align-items: center;
  .imgBox {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
}
.nav {
  margin: 0 auto;
  color: #666666;
  height: 100%;
  .navList {
    display: flex;
    align-items: center;
    height: 100%;
    .li {
      height: 100%;
      line-height: 80px;
      padding: 0 1vw;
      cursor: pointer;
      &:hover {
        color: #3475ff;
        background-color: rgba($color: #3475ff, $alpha: 0.1);
      }
      &.active {
        color: #3475ff;
        background-color: rgba($color: #3475ff, $alpha: 0.1);
      }
    }
  }
}
.login {
  display: flex;
  align-items: center;
  .btn {
    background-color: #3475ff;
    margin-left: 15px;
    border-radius: 7px;
    padding: 6px 20px;
    cursor: pointer;
    position: relative;
    text-align: center;
    .login-list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: #fff;
      color: #3475ff;
      border: 1px solid #3475ff;
      border-radius: 8px;
      // border-top: none;
      div {
        line-height: 30px;
        border-bottom: 1px dashed #3475ff;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.phone {
  position: absolute;
  right: 2.6%;
  top: 50%;
  transform: translateY(-50%);
}
.login-bt {
  color: #333;
  font-size: 14px;
  span {
    display: inline-block;
    margin: 0 5px;
    border: 1px solid #409eff;
    border-radius: 6px;
    padding: 4px;
    font-size: 14px;
    &:hover {
      background-color: #409eff;
      cursor: pointer;
      color: #fff;
    }
  }
}
@media (max-width: 1700px) {
  .el-tabs .el-tabs__item {
    padding: 0 15px !important;
  }
}
@media (max-width: 1400px) {
  .el-tabs .el-tabs__item {
    padding: 0 10px !important;
  }
}
</style>
